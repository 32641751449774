<!--
 * @Description: 数据看板主页面
 * @Author: 小广
 * @Date: 2020-01-06 17:01:46
 * @LastEditors  : 小广
 * @LastEditTime : 2020-01-15 10:40:57
 -->
<template>
  <div ref="dataBoard">
    <module-box :appModule="appModule">
      <div class="data-board-wrapper">
        <div class="component-wrapper" :style="{width: dataBoardWidth +'%'}" v-for="(item, index) in dataList" :key="index">
          <component :is="item.componentName" :appModule="item.appModule || {}"></component>
        </div>
      </div>
    </module-box>
  </div>
</template>

<script >
// import _ from 'lodash'
import ModuleBox from '../ModuleBox'
import Grid from './Grid'
import VChartBar from './VChartBar'
import EChartPie from './EChartPie'
import { getAllModelItemsURL } from '../../api'

export default {
  name: '',
  // 引用的模块
  components: {
    ModuleBox
  },

  props: {
    leftIndex: {
      type: Number,
      default: 0
    },
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  watch: {
    leftIndex (newVal) {
      this.getWidth()
    },

    appModule (newVal) {
      if (newVal) {
        this.requestForGetData()
      }
    }
  },

  data () {
    return {
      dataBoardWidth: 50,
      sourceList: [
        { // 工单处理
          name: 'workOrderProcessing',
          componentName: Grid
        },
        { // 今日工单
          name: 'todayWorkOrder',
          componentName: VChartBar
        },
        { // 订单处理
          name: 'orderProcessing',
          componentName: Grid
        },
        { // 访客通行
          name: 'visitorTraffic',
          componentName: EChartPie
        },
        { // 临停车位使用情况
          name: 'temporaryParkingSpace',
          componentName: EChartPie
        },
        { // 快递数
          name: 'expressNumber',
          componentName: EChartPie
        }
      ],
      // 显示的数组
      dataList: []
    }
  },

  // created函数
  created () {
    this.requestForGetData()
  },

  mounted () {
    let _this = this
    this.$nextTick(() => {
      // 重新判断宽度
      _this.getWidth()
    })

    window.addEventListener('resize', this.getWidth, false)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getWidth, false)
  },

  // 方法
  methods: {
    getWidth () {
      // 获取到宽度后 改变style
      let tabWidth = this.$refs.dataBoard.clientWidth
      if (tabWidth < 950) {
        this.dataBoardWidth = 100
      } else {
        this.dataBoardWidth = 50
      }
    },

    async requestForGetData () {
      let params = {
        classification: this.appModule.classification,
        id: this.appModule.id
      }
      const { data, status } = await this.$axios.get(getAllModelItemsURL, { params })
      if (status === 100) {
        if (data) {
          this.dealWithData(data.childreds || [])
        }
      }
    },

    dealWithData (list) {
      let tempList = []
      if (list.length > 0) {
        list.forEach((item, index) => {
          let findItem = this.sourceList.find(sourceItem => sourceItem.name === item.classification)
          if (findItem && item.modelStatus === 1) {
            // 代表有对应显示的模块
            let pushItem = Object.assign({}, { appModule: item }, findItem)
            tempList.push(pushItem)
          }
        })
      }
      this.dataList = tempList
    }
  }
}
</script>

<style scoped lang="scss">
 .data-board-wrapper {
   display: flex;
  //  background-color: #ffffff;
   flex-wrap: wrap;
  //  padding: 0 24px 24px 24px;

  .component-wrapper {
    overflow: hidden;
  }
 }
</style>
