var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-chart-bar-wrapper" },
    [
      _c("data-header", { attrs: { title: _vm.appModule.modelAlias } }),
      _c("div", { staticClass: "content-title" }, [
        _vm._v("今日工单总数   " + _vm._s(_vm.totalNumber)),
      ]),
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "chart-bar-data-wrapper" },
          [
            _c("div", { staticClass: "chart-bar-text" }, [
              _vm._v(_vm._s(item.name)),
            ]),
            _c("div", { staticClass: "chart-bar-wrapper" }, [
              _c("div", {
                staticClass: "chart-sub-bar-wrapper",
                style: _vm.domStyle(item),
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "chart-bar-text right-text",
                style: [
                  { backgroundColor: item.color },
                  { color: item.background },
                ],
              },
              [_vm._v(_vm._s(item.count || 0))]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }