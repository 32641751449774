var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-header-wrapper" }, [
    _c("div", { staticClass: "head-left-mark" }),
    _c("div", { staticClass: "head-title" }, [_vm._v(_vm._s(_vm.showTitle))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }