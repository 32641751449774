import echarts from 'echarts'
import liquidFill from 'echarts-liquidfill' // eslint-disable-line

/// 水波图
export function drawWaterChart (rate, color, bgColor, eleId) {
  let option = {
    series: [{
      type: 'liquidFill',
      radius: '80%',
      // center: ['50%', '60%'],
      data: [rate],
      color: [color],
      amplitude: 5,
      backgroundStyle: {
        color: bgColor
      },
      label: {
        normal: {
          color: 'white',
          insideColor: 'white',
          fontSize: 18,
          formatter: function (param) {
            var value = ((param.value) * 100).toFixed(1) + '%'
            return value
          }
        }
      },
      outline: {
        show: false
      },
      animation: false,
      waveAnimation: false // 禁止左右波动
    }]
  }
  return drawedChart(eleId, option)
}

// 绘制图表
export function drawedChart (eleId, option) {
  let chart = null
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId))
    chart.setOption(option)
  }
  return chart
}

/// 2018-06-04 格式的日期 转换成  6.4
export function chartsDateConvertStr (t) {
  let r = t.substring(5, t.length)
  if (r.substring(0, 1) === '0') {
    r = r.substring(1, r.length)
  }
  r = r.replace(/-/, '.')
  var ary = r.split('.')
  if (ary[1].charAt(0) === '0') {
    var tmp = ary[1].substring(1, ary[1].length)
    r = ary[0] + '.' + tmp
  }
  return r
}

/// 2018-04 格式化日期为 4
export function chartsYmDateConvertStr (t) {
  var r = t.substring(5, t.length)
  if (r.substring(0, 1) === '0') {
    r = r.substring(1, r.length)
  }
  r = r.replace(/-/, '.')
  return r
}

// 渐变色
export function linearGradientColor (colorBegin, colorEnd) {
  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    offset: 0,
    color: colorBegin
  }, {
    offset: 1,
    color: colorEnd
  }])
}

// grid 配置
export function gridOpts (option) {
  option = option || {}
  return {
    left: option.left || 24,
    right: option.right || 24,
    bottom: open.bottom || 24,
    top: option.top || 24,
    containLabel: true
  }
}

// 纵轴为value时的默认配置
export function yAxisValueOpts (option) {
  option = option || {}
  return {
    type: option.type || 'value',
    name: option.name || '',
    min: 0,
    axisLabel: {
      show: true,
      textStyle: {
        color: '#AAA'
      }
    },
    minInterval: 1,
    nameTextStyle: {
      color: '#333',
      fontSize: 14
    },
    axisLine: {
      lineStyle: {
        color: '#FFF'
      }
    }
  }
}

// 横轴为类型（主要是时间等）的默认配置
export function xAxisCategoryOpts (option) {
  option = option || {}
  return {
    type: 'category',
    boundaryGap: option.boundaryGap || false,
    name: option.name || '',
    data: option.data || [],
    axisLine: {
      lineStyle: {
        color: option.lineColor || '#E0E0E0'
      }
    },
    nameTextStyle: {
      color: option.nameColor || '#333'
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: option.labelColor || '#333'
      }
    },
    axisTick: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: option.splitColor || '#E0E0E0'
      }
    }
  }
}

// 平滑折线图series配置
export function smoothLineChartSeriesOpts (option) {
  option = option || {}
  return {
    name: option.name || '',
    data: option.data || [],
    type: 'line',
    stack: option.stack || 0,
    smooth: true,
    showSymbol: false,
    smoothMonotone: 'x',
    areaStyle: {
      color: option.areaColor
    },
    itemStyle: {
      color: option.color
    }
  }
}

// 柱状图series配置
export function barChartSeriesOpts (option) {
  return {
    name: option.name || '',
    data: option.data || [],
    type: 'bar',
    stack: option.stack || 0,
    barWidth: option.barWidth || 12,
    barGap: option.barGap || 0,
    itemStyle: {
      normal: {
        barStyle: {
          color: option.color || '#0D9DF2'
        },
        color: option.color || '#0D9DF2'
      }
    }
  }
}

// 饼图series配置
export function pieChartSeriesOpts (option) {
  return {
    name: option.name || '',
    type: 'pie',
    radius: option.radius || ['60%', '80%'],
    center: option.center || ['50%', '50%'],
    avoidLabelOverlap: true,
    hoverAnimation: false,
    label: option.label || {
      normal: {
        show: true,
        position: 'center',
        textStyle: {
          fontSize: option.labelFont || '24',
          fontWeight: 'bold',
          color: option.labelColor || '#1B8CF2'
        }
      }
    },
    data: option.data || []
  }
}

// 饼图默认legend配置
export function pieChartDefaultLegendOpts () {
  return {
    orient: 'horizontal',
    itemWidth: 7,
    itemHeight: 7,
    selectedMode: false,
    formatter: function (name) {
      return name
    }
  }
}
/// 水波图缴费概览
export function drawWaterChart2 (rate, color, bgColor, eleId, titleName, extraData) {
  let titleFontSize = extraData && extraData.titleFontSize ? extraData.titleFontSize : 16
  let labelFontSize = extraData && extraData.labelFontSize ? extraData.labelFontSize : 30

  let option = {
    title: {
      show: true,
      text: titleName || '',
      textStyle: {
        color: '#fff',
        fontSize: titleFontSize
      },
      left: 'center',
      top: '60%'
    },
    series: [{
      type: 'liquidFill',
      radius: '80%',
      // center: ['50%', '60%'],
      data: [rate],
      color: [color],
      amplitude: 5,
      backgroundStyle: {
        color: bgColor
      },
      label: {
        normal: {
          color: 'white',
          insideColor: 'white',
          fontSize: labelFontSize,
          formatter: function (param) {
            var value = ((param.value) * 100).toFixed(2) + '%'
            return value
          }
        }
      },
      outline: {
        show: false
      },
      animation: false,
      waveAnimation: true
    }]
  }
  return drawedChart(eleId, option)
}
