var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid-view-wrapper" },
    [
      _c("data-header", { attrs: { title: _vm.appModule.modelAlias } }),
      _c(
        "div",
        { staticClass: "grid-content-wrapper" },
        _vm._l(_vm.dataList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "grid-cell-wrapper",
              on: {
                click: function ($event) {
                  return _vm.click(item)
                },
              },
            },
            [
              _c("div", { staticClass: "grid-item-wrapper" }, [
                _c("div", [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "item-number-big-text" }, [
                    _vm._v(_vm._s(item.count || "0")),
                  ]),
                ]),
                _c("div", [
                  item.subItem !== undefined
                    ? _c(
                        "span",
                        {
                          staticClass: "item-sub-text",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.click(item.subItem)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                `${item.subName} ${item.subItem.count || "0"}`
                              ) +
                              "  \n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }