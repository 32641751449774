<!--
 * @Description: 数据看板--宫格
 * @Author: 小广
 * @Date: 2020-01-06 17:03:20
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-24 09:34:10
 -->
<template>
  <div class="grid-view-wrapper">
    <data-header :title="appModule.modelAlias"></data-header>
    <div class="grid-content-wrapper">
      <div class="grid-cell-wrapper" v-for="(item, index) in dataList" :key="index" @click="click(item)">
        <div class="grid-item-wrapper">
          <div>
            <div class="item-title">{{item.name}}</div>
            <div class="item-number-big-text">{{item.count || '0'}}</div>
          </div>
          <div>
              <span
                v-if="item.subItem !== undefined"
                class="item-sub-text"
                @click.stop="click(item.subItem)">
                &nbsp;&nbsp;{{`${item.subName} ${item.subItem.count || '0'}`}}&nbsp;&nbsp;
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import DataHeader from './DataHeader'
import { getDataKanBanSubDataURL, getNewWorkOrderPermission,get3WorkOrderPermission } from '../../api'
import moment from 'moment'
import {
  workOrderStatusMap,
  workNewOrderStatusMap,
  orderStatusMap
} from './map'
export default {
  name: 'grid',
  // 引用的模块
  components: {
    DataHeader
  },
  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      // 工单的数组
      sourceWorkOrderList: [
        {
          name: '待接收',
          value: 'workOrderToBeReceived',
          subName: '已超时', // 超时待接收
          subValue: 'overtimeReceivingWorkOrder'
        },
        {
          name: '待处理',
          value: 'workOrderToBeProcessed',
          // 1.22 小尚让放出来
          subName: '已超时', // 超时待处理
          subValue: 'overtimeProcessingWorkOrder'
        },
        {
          name: '已处理',
          value: 'workOrderProcessedToday'
        },
        {
          name: '待回访',
          value: 'workOrderToBeVisited'
        }
      ],
      // 订单的数组
      sourceOrderList: [
        {
          name: '待接收',
          value: 'orderToBeReceived',
          subName: '已超时', // 超时待接收
          subValue: 'overtimeReceivingOrders'
        },
        {
          name: '待处理',
          value: 'pendingOrders',
          // 1.23产品要求放出来
          subName: '已超时', // 超时待处理
          subValue: 'overtimeOrderProcessing'
        },
        {
          name: '已处理',
          value: 'ordersProcessedToday'
        },
        {
          name: '售后中',
          value: 'afterSale',
          subName: '待退款', // 待退款
          subValue: 'pendingRefund'
        }
      ],
      // 展示的数据数组
      dataList: [],
      timer: undefined,
      hasNewWorkOrderPermission: false,// 新工单权限
    }
  },
  computed: {
    // 工单
    workOrderViewPermission () {
      // 这一块代码不用了 直接return true
      
      // 权限读取
      let view = true
      // let permission = this.$store.state.menuStore.menuPermission['orderAction!viewList.action?orderType=01']
      // if (permission && permission.view === 1) {
      //   view = true
      // }
      return view
    },

    // 订单
    orderViewPermission () {
      // 权限读取
      let view = false
      let permission = this.$store.state.menuStore.menuPermission['orderAction!viewList.action?orderType=11']
      if (permission && permission.view === 1) {
        view = true
      }
      return view
    },

    // 售后
    afterSalesViewPermission () {
      // 权限读取
      let view = false
      let permission = this.$store.state.menuStore.menuPermission['afterSalesAction!getList.action1']
      if (permission && permission.view === 1) {
        view = true
      }
      return view
    },

    // 退款
    refundViewPermission () {
      // 权限读取
      let view = false
      let permission = this.$store.state.menuStore.menuPermission['orderSettleAction!list.action1']
      if (permission && permission.view === 1) {
        view = true
      }
      return view
    },

    theme () {
      return this.$store.state.theme
    }
  },

  watch: {
    appModule (newVal) {
      if (newVal !== undefined) {
        this.intervalTodo()
      }
    }
  },

  destroyed () {
    clearInterval(this.timer)
  },

  // created函数
  created () {
  },

  mounted () {
    this.dealWithPostData()
    this.intervalTodo()
  },

  // 方法
  methods: {
    intervalTodo () {
      if (!this.timer) {
        let _this = this
        this.timer = setInterval(() => {
          _this.dealWithPostData()
        }, 1000 * 60 * 5)
      }
    },
    //工单3.0得账户权限和菜单权限不是同一个所以会出现4种情况
    async click (item) {
      const {data} = await get3WorkOrderPermission()
      const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
      const reMenuInfo = this.$store.getters['menuStore/getMenuInfo'](94746)
      let fid = 94751
      let sid = 947241
      //有工单3.0权限同时拥有新工单3.0回访 新工单3.0列表权限
      switch(item.classification){
        case'workOrderToBeReceived':{
          if(data.flag && menuInfo){
                // 跳转到工单3.0
              this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:fid,
                  sid:sid,
                  src:menuInfo.item.url,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  yshOrderStatus:'1,3',
                }
              })
          }else {
            await this.getNewWorkOrderPermission()
            // 跳转的处理，根据类型传递不同参数
            let jumpData = this.dealWithJumpData(item)
            if (jumpData.routerName && jumpData.routerName.length > 0) {
              console.log('jumpData.routerName', jumpData.routerName);
              this.$router.push({
                name: jumpData.routerName,
                query: jumpData.query
              })
            }
          }
          console.log('待接收')
          break;
        }
        case'workOrderToBeProcessed':{
          if(data.flag && menuInfo){
                // 跳转到工单3.0
              this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:fid,
                  sid:sid,
                  src:menuInfo.item.url,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  yshOrderStatus:'2,4',
                }
              })
          }else {
            await this.getNewWorkOrderPermission()
            // 跳转的处理，根据类型传递不同参数
            let jumpData = this.dealWithJumpData(item)
            if (jumpData.routerName && jumpData.routerName.length > 0) {
              console.log('jumpData.routerName', jumpData.routerName);
              this.$router.push({
                name: jumpData.routerName,
                query: jumpData.query
              })
            }
          }
          console.log('待处理')
          break;
        }
        case'workOrderProcessedToday':{
          if(data.flag && menuInfo){
                // 跳转到工单3.0
              this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:fid,
                  sid:sid,
                  src:menuInfo.item.url,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  yshOrderStatus:'6,7',
                }
              })
          }else {
            await this.getNewWorkOrderPermission()
            // 跳转的处理，根据类型传递不同参数
            let jumpData = this.dealWithJumpData(item)
            if (jumpData.routerName && jumpData.routerName.length > 0) {
              console.log('jumpData.routerName', jumpData.routerName);
              this.$router.push({
                name: jumpData.routerName,
                query: jumpData.query
              })
            }
          }
          console.log('已处理')
          break;
        }
        case'workOrderToBeVisited':{
          if(data.flag && reMenuInfo){
                // 跳转到工单3.0
              this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:94303,
                  sid:94746,
                  src:reMenuInfo.item.url,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  yshOrderStatus:7,
                }
              })
          }else {
            await this.getNewWorkOrderPermission()
            // 跳转的处理，根据类型传递不同参数
            let jumpData = this.dealWithJumpData(item)
            if (jumpData.routerName && jumpData.routerName.length > 0) {
              console.log('jumpData.routerName', jumpData.routerName);
              this.$router.push({
                name: jumpData.routerName,
                query: jumpData.query
              })
            }
          }
          console.log('待回访')
          break;
        }
        default:{
          break;
        }
      }
    },

    // 处理跳转数据
    dealWithJumpData (item) {
      console.log(this.appModule, 'this.appModule')
      console.log('dealWithJumpData', item);
      let routerName = ''
      let query = {
        // 代表是跳转传参
        fromPage: 'workbench'
      }

      let hasPermission = true

      if (item && item.classification) {
        // 处理通用搜索条件和路由
        if (this.appModule.classification === 'workOrderProcessing') {
          // 工单处理 跳转至应用中心 > 项目应用 > 服务工单
          if(this.hasNewWorkOrderPermission) {// 新工单
            routerName = 'orderScheduling.ServiceWorkOrder'
            // query['orderStatus'] = workNewOrderStatusMap[item.classification]
            //  提交时间段： 今日往前90天~今日
            query['referStartTime'] = moment().subtract(90, 'days').format('YYYY-MM-DD HH:mm:ss')
            query['referEndTime'] = moment().format('YYYY-MM-DD HH:mm:ss')
          }else { // 老工单
            routerName = 'orderAction!viewList.action?orderType=01'
            query['orderStatus'] = workOrderStatusMap[item.classification]
            //  提交时间段： 今日往前90天~今日
            query['referStartTime'] = moment().subtract(90, 'days').format('YYYY-MM-DD HH:mm')
            query['referEndTime'] = moment().format('YYYY-MM-DD HH:mm')
          }
          // routerName = 'orderAction!viewList.action?orderType=01'
          // 权限判断
          hasPermission = this.workOrderViewPermission
        } else if (this.appModule.classification === 'orderProcessing') {
        // 订单处理 跳转至商业中心 > 订单管理 > 商业订单
          routerName = 'orderAction!viewList.action?orderType=11'
          if (item.classification === 'afterSale') {
          // 售后
            routerName = 'afterSalesAction!getList.action1'
            // 权限判断
            hasPermission = this.afterSalesViewPermission
          } else if (item.classification === 'pendingRefund') {
          // 退款
            routerName = 'orderSettleAction!list.action1'
            // 权限判断
            hasPermission = this.refundViewPermission
          } else {
            //  提交时间段： 今日往前90天~今日
            query['referStartTime'] = moment().subtract(90, 'days').format('YYYY-MM-DD HH:mm')
            query['referEndTime'] = moment().format('YYYY-MM-DD HH:mm')
            // 权限判断
            hasPermission = this.orderViewPermission
          }
          query['orderStatus'] = orderStatusMap[item.classification]
        }

        if (!hasPermission) {
          this.$message('您没有查看权限，请联系管理员添加')
          return {}
        }

        // 其他额外搜索条件
        if (item.classification.length > 0) {
          let classification = item.classification
          if(this.hasNewWorkOrderPermission) {// 新工单
            if(classification === 'workOrderToBeReceived') {
              // 点击待接受
              query['orderStatusList'] = '1,3' // 待接收,超时待(未)接收
            }else if(classification === 'workOrderToBeProcessed'){
              // 点击待处理
              query['orderStatusList'] = '2,4' // 待处理,超时待(未)处理
            }else if(classification === 'workOrderProcessedToday') {
              // 点击已处理
              query['orderStatusList'] = '6,7' // 已处理,已完成
            }else if (classification === 'workOrderToBeVisited') {
              // 点击待回访
              // 待回访工单  是否回访:否
              query['isReturnVisit'] = 1
              query['orderStatusList'] = '6,7' // 已处理,已完成
            } 
          }else {// 老工单
            if (classification === 'overtimeReceivingWorkOrder') {
              // 工单处理
              //  超时待接收工单  优先处理：15分钟未接收
              query['caseType'] = 2
            } else if (classification === 'overtimeProcessingWorkOrder') {
              // 工单处理
              // 超时待处理工单 梁俊说取值 10天及以上未处理
              query['caseType'] = 6
            } else if (classification === 'workOrderProcessedToday') {
              // 工单处理
              // 今日已处理工单  完成处理时间 新增 工单状态：已处理和已评价
              query['startDealTime'] = moment().format('YYYY-MM-DD 00:00')
              query['endDealTime'] = moment().format('YYYY-MM-DD HH:mm')
              query['orderStatusList'] = '3,4'
            } else if (classification === 'workOrderToBeVisited') {
              // 工单处理
              // 待回访工单  是否回访:否
              query['isReturnVisit'] = 1
              // 剔除已关闭状态
              query['orderStatusList'] = '3,4'
            } else if (classification === 'overtimeReceivingOrders') {
              // 订单处理
              //  超时待接收工单  优先处理：超时待接收
              query['caseType'] = 2
            } else if (classification === 'overtimeOrderProcessing') {
              // 订单处理
              // 超时待接收订单  梁俊说取值 10天及以上未处理
              query['caseType'] = 6
            } else if (classification === 'ordersProcessedToday') {
              // 订单处理
              // 今日已处理订单  完成处理时间  订单状态：已处理和已完成
              query['startDealTime'] = moment().format('YYYY-MM-DD 00:00')
              query['endDealTime'] = moment().format('YYYY-MM-DD HH:mm')
              query['orderStatusList'] = '3,4'
            } else if (classification === 'afterSale') {
              // 订单处理
              // 售后中 等待退款、等待受理、寄回商品、等待收货、等待审核
              query['serviceStatusList'] = '1,2,3,4,8'
            } else if (classification === 'pendingRefund') {
              // 订单处理
              // 待退款  退款状态-等待退款
              query['serviceStatus'] = 4
            }
          }
        }
      }
      return { routerName, query }
    },
    dealWithPostData () {
      // 获取到的数据 先请求下
      if (this.appModule && this.appModule.classification) {
        let childrenList = this.appModule.childreds || []
        if (childrenList.length > 0) {
          let modelIds = childrenList.map(item => item.refId) || []
          if (modelIds.length > 0) {
            this.requestForData(modelIds)
          }
        }
      }
    },

    stringWithNumFormat (num) {
      let tempNum = num || 0
      let suffixStr = ''
      if (tempNum >= 10000 && tempNum < 100000000) {
        tempNum = tempNum / 10000.00
        suffixStr = '万'
      } else if (tempNum > 100000000) {
        tempNum = tempNum / 100000000.00
        suffixStr = '亿'
      }
      tempNum = tempNum.toFixed(2)
      tempNum = parseFloat(tempNum)
      tempNum = tempNum.toLocaleString()
      return tempNum + suffixStr
    },

    // 处理数据
    dealWithData (list) {
      if (this.appModule && this.appModule.classification) {
        let sourceList = []
        if (this.appModule.classification === 'workOrderProcessing') {
          // 工单处理
          sourceList = this.sourceWorkOrderList
        } else if (this.appModule.classification === 'orderProcessing') {
          // 订单处理
          sourceList = this.sourceOrderList
        }
        // 处理数据
        if (sourceList.length > 0) {
          let showList = []
          sourceList.forEach(sourceItem => {
            let findItem = list.find(children => sourceItem.value === children.classification) || {}
            if (findItem.count) {
              findItem.count = this.stringWithNumFormat(findItem.count)
            }
            let pushItem = Object.assign({}, sourceItem, findItem)
            if (sourceItem.subValue) {
              let findSubItem = list.find(childrenItem => sourceItem.subValue === childrenItem.classification) || {}
              if (findSubItem.count) {
                findSubItem.count = this.stringWithNumFormat(findSubItem.count)
              }
              pushItem.subItem = findSubItem
            }
            showList.push(pushItem)
          })
          this.dataList = showList
        }
      }
    },

    // 请求数据
    async requestForData (modelIds) {
      let params = { modelIds }
      const { data, status } = await this.$axios.post(getDataKanBanSubDataURL, params)
      if (status === 100) {
        if (data) {
          this.dealWithData(data || [])
        }
      }
    },
    async getNewWorkOrderPermission() {
      const { data, status } = await this.$axios.get(getNewWorkOrderPermission)
      if (status === 100) {
        if (data) {
          this.hasNewWorkOrderPermission = data
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .grid-view-wrapper {
   padding: 0 24px 24px 24px;
   // 最小宽度500，减去左右的24 就是452px
   min-width: 452px;

   .grid-content-wrapper {
        padding-top: 14px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .grid-cell-wrapper {
          min-width: 200px;

          &:hover {
            cursor: pointer;
          }

          .grid-item-wrapper {
            display: flex;
            margin: 2.5px 5px 2.5px 2.5px;
            justify-content: space-between;
            background: #F8F9F9;
            // box-shadow:0px 2px 16px 0px rgba(27,140,242,0.15);
            border-radius:4px;
            color: #333333;
            padding: 20px;
            &:hover {
              background: #5A8DEE;
              box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.37);
              .item-title,.item-number-big-text {
                color: #fff;  

              }
              .item-sub-text {
                color: #fff;
                border-color: #fff;
                background: rgba(255,102,72,0);
              }
            }

            .item-title {
              color: #919FB0;
              font-size:12px;
              font-weight:400;
            }

            .item-number-big-text {
              color: #475F7B;
              text-align: left;
              font-size:18px;
              font-weight:600;
              margin-top: 16px;
              overflow: hidden;
            }

            .item-sub-text {
                height:20px;
                font-size:12px;
                line-height:20px;
                color:rgba(255,102,72,1);
                background:rgba(255,102,72,0.2);;
                border:1px solid rgba(255,102,72,1);;
                border-radius:10px;
            }
          }

        }
    }
 }
</style>
