<!--
 * @Description: 数据看板--每个模块的header
 * @Author: 小广
 * @Date: 2020-01-06 17:45:59
 * @LastEditors  : 小广
 * @LastEditTime : 2020-01-14 15:17:44
 -->
<template>
  <div class="data-header-wrapper">
      <div class="head-left-mark"></div>
      <div class="head-title">{{showTitle}}</div>
  </div>
</template>

<script >

export default {
  name: 'data-header',
  // 引用的模块
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showTitle: this.title || ''
    }
  },

  watch: {
    title (neVal) {
      this.showTitle = neVal || ''
    }
  },
  computed: {
    theme () {
      return this.$store.state.theme
    }
  },

  // created函数
  created () {
  },

  // 方法
  methods: {
  }
}
</script>

<style scoped lang="scss">
 .data-header-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    .head-left-mark {
        margin-right: 20px;
        width:4px;
        height:20px;
        background: #1B8CF2;
        border-radius:2px;
    }

    .head-title {
        font-size:18px;
        font-weight:600;
        color:#475F7B;
    }
 }
</style>
