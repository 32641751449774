<!--
 * @Description: 自定义柱形图（今日工单使用）
 * @Author: 小广
 * @Date: 2020-01-07 11:33:00
 * @LastEditors  : 小广
 * @LastEditTime : 2020-01-14 15:21:56
 -->
<template>
  <div class="v-chart-bar-wrapper">
    <data-header :title="appModule.modelAlias"></data-header>
    <div class="content-title">今日工单总数&nbsp;&nbsp;&nbsp;{{totalNumber}}</div>
    <div class="chart-bar-data-wrapper" v-for="(item, index) in dataList" :key="index">
        <div class="chart-bar-text">{{item.name}}</div>
        <div class="chart-bar-wrapper">
            <div class="chart-sub-bar-wrapper" :style="domStyle(item)"></div>
        </div>
        <div class="chart-bar-text right-text" :style="[{'backgroundColor': item.color},{'color':item.background}]">{{item.count || 0}}</div>
    </div>
  </div>
</template>

<script >
import DataHeader from './DataHeader'
import { getDataKanBanSubDataURL, getWorkOrderTypeCount, getNewWorkOrderPermission, get3WorkOrderPermission } from '../../api'

export default {
  name: 'v-chart-bar',
  // 引用的模块
  components: {
    DataHeader
  },

  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    domStyle () {
      return (style) => {
        let domStyle = { }
        if (style.width !== undefined) {
          domStyle.width = `${style.width}%`
        }

        // if (style.left !== undefined) {
        //   domStyle.left = `${style.left}%`
        // }
        if (style.background !== undefined) {
          domStyle.backgroundColor = style.background
        }
        return domStyle
      }
    },
    theme () {
      return this.$store.state.theme
    }
  },

  data () {
    return {
      totalNumber: 0,
      // 今日工单
      sourceWorkOrderList: [
        {
          name: '维修',
          value: 'maintenanceNumber',
          count: 10,
          background: 'rgb(90,140,238)',
          color: 'rgb(226,236,255)'

        },
        {
          name: '报事',
          value: 'reportingNumber',
          count: 40,
          background: 'rgb(0,207,221)',
          color: 'rgb(224,249,251)'
        },
        {
          name: '投诉',
          value: 'complaintNumber',
          count: 10,
          background: 'rgb(57,218,138)',
          color: 'rgb(231,250,241)'
        },
        {
          name: '其它',
          value: 'numberOfOtherWorkOrders',
          count: 20,
          background: 'rgb(255,91,92)',
          color: 'rgb(255,235,235)'
        }
      ],
      dataList: [],
      timer: undefined,
      hasNewWorkOrderPermission: false
    }
  },

  watch: {
    appModule (newVal) {
      if (newVal !== undefined) {
        this.intervalTodo()
      }
    }
  },

  destroyed () {
    clearInterval(this.timer)
  },

  // created函数
  created () {
  },

  mounted () {
    this.dealWithPostData()
    this.intervalTodo()
  },

  // 方法
  methods: {

    intervalTodo () {
      if (!this.timer) {
        let _this = this
        this.timer = setInterval(() => {
          _this.dealWithPostData()
        }, 1000 * 60 * 5)
      }
    },

    async dealWithPostData () {
      await this.getNewWorkOrderPermission()
      const {data} = await get3WorkOrderPermission()
      if(this.hasNewWorkOrderPermission || data.flag) {
        this.getWorkOrderTypeCount()
      }else {
        // 获取到的数据 先请求下
        if (this.appModule && this.appModule.classification) {
          let childrenList = this.appModule.childreds || []
          if (childrenList.length > 0) {
            let modelIds = childrenList.map(item => item.refId) || []
            if (modelIds.length > 0) {
              this.requestForData(modelIds)
            }
          }
        }
      }

    },
    async getNewWorkOrderPermission() {
      const { data, status } = await this.$axios.get(getNewWorkOrderPermission)
      if (status === 100) {
        if (data) {
          this.hasNewWorkOrderPermission = data
        }
      }
    },
    // 处理数据
    dealWithData (list) {
      console.log(list)
      if (this.appModule && this.appModule.classification) {
        let sourceList = []
        if (this.appModule.classification === 'todayWorkOrder') {
          // 工单处理
          sourceList = this.sourceWorkOrderList
        }
        // 处理数据
        if (sourceList.length > 0) {
          let showList = []
          let countList = []
          if (list.length > 0) {
            countList = list.map(model => model.count || 0) || []
          }
          let totalNumber = 0
          countList.forEach(count => {
            totalNumber += count
          })
          // 算出总数量
          this.totalNumber = totalNumber

          let tempList = []
          sourceList.forEach((sourceItem, sourceIndex) => {
            let findItem = list.find(children => sourceItem.value === children.classification)
            // 取到数值
            let tempCount = findItem ? findItem.count || 0 : 0
            let left = 0
            tempList.push(tempCount)
            if (sourceIndex > 0) {
              // 计算出距离左侧距离
              left = this.getCurrentLeft(sourceIndex, tempList)
            }
            if (findItem) {
              let width = 0
              if (totalNumber > 0 && findItem.count > 0) {
                // 计算出当前宽度
                width = findItem.count / totalNumber * 100.00
              }
              let style = {
                width: width,
                left: left
              }
              let pushItem = Object.assign(style, sourceItem, findItem)
              showList.push(pushItem)
            } else {
              // 没找到就要写默认值
              showList.push(sourceItem)
            }
          })
          this.dataList = showList
        }
      }
    },
    // 新处理数据
    dealWithDataNew (list) {
      console.log(list)
      const colorList = [
        {
          background: 'rgb(90,140,238)',
          color: 'rgb(226,236,255)'
        },
        {
          background: 'rgb(0,207,221)',
          color: 'rgb(224,249,251)'
        },
        {
          background: 'rgb(57,218,138)',
          color: 'rgb(231,250,241)'
        },
        {
          background: 'rgb(255,91,92)',
          color: 'rgb(255,235,235)'
        }
      ]
      const showList = []
      let totalNumber = 0
      if (list.length > 0) {
        list.forEach(item => {
          let count = item.count || 0
          totalNumber += count
          return count
        })
      }
      // 算出总数量
      this.totalNumber = totalNumber
      
      let tempList = []
      list.forEach((item, index) => {
        let left = 0
        tempList.push(item.count)
        if (index > 0) {
          // 计算出距离左侧距离
          left = this.getCurrentLeft(index, tempList)
        }
        let width = 0
        if (totalNumber > 0 && item.count > 0) {
          // 计算出当前宽度
          width = item.count / totalNumber * 100.00
        }
        let style = {
          width: width,
          left: left
        }
        item.name = item.typeName
        item.color = colorList[index].color
        item.background = colorList[index].background
        let pushItem = Object.assign(style, item)
        showList.push(pushItem)
      })
      this.dataList = showList
    },

    // 获取距左边的距离
    getCurrentLeft (currentIndex, list) {
      let currentLeft = 0
      let currentTotalCount = 0
      if (list && list.length > 0 && list.length > currentIndex) {
        for (let index = 0; index < currentIndex; index++) {
          currentTotalCount += (list[index] || 0)
        }
      }
      if (this.totalNumber > 0 && currentTotalCount > 0) {
        currentLeft = currentTotalCount / this.totalNumber * 100.00
      }

      return currentLeft
    },
    // 请求数据
    async requestForData (modelIds) {
      let params = { modelIds }
      const { data, status } = await this.$axios.post(getDataKanBanSubDataURL, params)
      if (status === 100) {
        if (data) {
          this.dealWithData(data || [])
        }
      }
    },
    // 新请求数据
    async getWorkOrderTypeCount () {
      const { data, status } = await this.$axios.get(getWorkOrderTypeCount)
      if (status === 100) {
        if (data) {
          this.dealWithDataNew(data || [])
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
 .v-chart-bar-wrapper {
   text-align: left;
   padding: 0 24px 24px 24px;
   min-width: 452px;

   .content-title {
       font-size:14px;
       font-weight:400;
       line-height:20px;
       color: #475F7B;
       margin-top: 20px;
       margin-bottom: 12px;
   }

   .chart-bar-data-wrapper {
       width: 100%;
       display: flex;
       flex-direction: row;
       align-items: center;
       margin-bottom: 16px;

       .chart-bar-text {
          font-size:14px;
          font-weight:500;
          line-height:20px;
          color:#475F7B;
          width: 60px;
          flex-shrink: 0;
          overflow : hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
       }

      .right-text {
        width: 50px;
        padding: 10px;
        font-size: 18px;
        text-align: center;
        margin-left: 10px;
        border-radius: 5px;
      }

       .chart-bar-wrapper {
        // chart图
            position:relative;
            overflow: hidden;
            flex: 1;
            height: 12px;
            background: rgb(231,237,243);
            border-radius: 6px;
       }

       .chart-sub-bar-wrapper {
           position: absolute;
           height: 100%;
       }
   }
 }
</style>
