<!--
 * @Description: 数据看板--饼形图(环形也属于饼图)
 * @Author: 小广
 * @Date: 2020-01-06 17:04:18
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-22 14:45:11
 -->
<template>
  <div class="e-chart-pie-wrapper">
    <data-header :title="appModule.modelAlias"></data-header>
    <div class="chart-pie-data-content-wrapper">
      <div class="chart-pie-wrapper" :id="chartPieId"></div>
      <div class="data-content-wrapper">
        <div class="data-cell-wrapper" v-if="detailItem.rightTipTitle">
          <div class="cell-text bold-text">{{detailItem.rightTipTitle}}</div>
          <div class="cell-text bold-text right-text">{{`${detailItem.rightTipCount}${detailItem.unit}`}}</div>
        </div>
        <div class="data-cell-wrapper" v-for="(item, index) in showDataList" :key="index">
          <div class="mark-wrapper">
            <div class="mark" :style="{backgroundColor: item.color}"></div>
            <div class="cell-text">{{item.name}}</div>
          </div>
          <div class="cell-text">{{`${item.percent}%`}}</div>
          <div class="cell-text right-text">{{`${item.number}${detailItem.unit}`}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import DataHeader from './DataHeader'
import { getDataKanBanSubDataURL } from '../../api'
import {
  drawedChart
} from 'common/echartsUtils'

export default {
  name: 'e-chart-pie',
  // 引用的模块
  components: {
    DataHeader
  },
  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      chartPieId: '',
      chartsAry: [],
      showDataList: [],
      detailItem: {},
      sourceDetailList: [
        {
          classification: 'visitorTraffic',
          unit: '人',
          chartTitle: '0',
          chartSubTitle: '今日访客',
          totalNumber: 0
        },
        {
          classification: 'expressNumber',
          unit: '件',
          chartTitle: '0',
          chartSubTitle: '快递总数',
          rightTipTitle: '今日新增快递',
          rightTipCount: '',
          totalNumber: 0
        },
        {
          classification: 'temporaryParkingSpace',
          unit: '个',
          chartTitle: '0',
          chartSubTitle: '剩余临停车位',
          rightTipTitle: '临停车位总数',
          // 右侧tip数量
          rightTipCount: '',
          // 总数，处理右侧比例时会用到
          totalNumber: 0
        }
      ],
      // 访客
      sourceVisitorList: [
        {
          name: '未离园',
          value: 'notLeavingTheGarden',
          color: '#5A8DEE'
        },
        {
          name: '已离园',
          value: 'outOfTheGarden',
          color: '#FF5B5C'
        }
      ],
      // 临停
      sourceParkList: [
        {
          name: '剩余临停车位',
          value: 'ordersProcessedToday',
          color: '#5A8DEE'
        },
        {
          name: '已使用车位',
          value: 'ordersProcessedToday',
          color: '#FF5B5C'
        }
      ],
      // 快递
      sourceExpressList: [
        {
          name: '已领取',
          value: 'alreadyLed',
          color: '#5A8DEE'
        },
        {
          name: '未领取',
          value: 'unclaimed',
          color: '#FF5B5C'
        }
      ],
      timer: undefined
    }
  },

  watch: {
    appModule (newVal) {
      if (newVal !== undefined) {
        this.intervalTodo()
      }
    }
  },

  destroyed () {
    clearInterval(this.timer)
  },

  computed: {
    theme () {
      return this.$store.state.theme
    }
  },

  // created函数
  created () {
    this.dealWithPostData()
    this.intervalTodo()
  },

  mounted () {
    let _this = this
    this.$nextTick(() => {
      // 重新绘制
      _this.eChartResize()
    })

    window.onresize = () => {
      // 重新绘制
      _this.eChartResize()
    }
  },

  // 方法
  methods: {

    intervalTodo () {
      if (!this.timer) {
        let _this = this
        this.timer = setInterval(() => {
          _this.dealWithPostData()
        }, 1000 * 60 * 5)
      }
    },

    dealWithPostData () {
      // 获取到的数据 先请求下
      if (this.appModule && this.appModule.classification) {
        this.chartPieId = this.appModule.classification
        let childrenList = this.appModule.childreds || []
        if (childrenList.length > 0) {
          let modelIds = childrenList.map(item => item.refId) || []
          if (modelIds.length > 0) {
            this.requestForData(modelIds)
          }
        }
      }
    },

    // 处理数据
    dealWithData (list) {
      if (this.appModule && this.appModule.classification) {
        let sourceList = []
        if (this.appModule.classification === 'visitorTraffic') {
          // 访客通行
          sourceList = this.sourceVisitorList
        } else if (this.appModule.classification === 'temporaryParkingSpace') {
          // 临停
          sourceList = this.sourceParkList
        } else if (this.appModule.classification === 'expressNumber') {
          // 快递
          sourceList = this.sourceExpressList
        }

        // 处理数据
        if (sourceList.length > 0) {
          this.dealWithDetailData(list)
          let showList = []
          sourceList.forEach(sourceItem => {
            let findItem = list.find(children => sourceItem.value === children.classification) || {}
            if (findItem) {
              let totalNumber = this.detailItem.totalNumber || 0
              let percent = totalNumber > 0 ? (findItem.count || 0) / totalNumber * 100.00 : 0
              let showItem = {
                percent: percent.toFixed(2),
                number: this.stringWithNumFormat(findItem.count || 0)
              }
              let pushItem = Object.assign(showItem, sourceItem, findItem)
              showList.push(pushItem)
            } else {
              // 不存在要写默认值
              showList.push(sourceItem)
            }
          })
          this.showDataList = showList
          // 数据处理好，开始绘制
          this.startDraw()
        }
      }
    },

    // 处理除了出了右侧以外的数据
    dealWithDetailData (list) {
      let showData = {}
      let currentClassification = this.appModule.classification
      if (currentClassification === 'visitorTraffic') {
        // 访客通行
        let chartTitle = ''
        let todayData = this.findItem(list, 'numberOfVisitorsToday')
        if (todayData) {
          chartTitle = this.stringWithNumFormat(todayData.count || 0)
        }
        showData = {
          unit: '人',
          chartTitle: chartTitle,
          chartSubTitle: '今日访客',
          totalNumber: todayData ? todayData.count || 0 : 0
        }
      } else if (currentClassification === 'temporaryParkingSpace') {
        // 临停
        let chartTitle = ''
        let rightTipCount = '0'
        // 临停车位总数
        let totalData = this.findItem(list, 'totalNumberTemporaryParkingSpaces')
        if (totalData) {
          rightTipCount = this.stringWithNumFormat(totalData.count || 0)
        }
        // 剩余剩余临停车位
        let surplusData = this.findItem(list, 'totalNumberTemporaryParkingSpaces')
        if (surplusData) {
          chartTitle = this.stringWithNumFormat(surplusData.count || 0)
        }
        showData = {
          unit: '个',
          chartTitle: chartTitle,
          chartSubTitle: '剩余临停车位',
          rightTipTitle: '临停车位总数',
          // 右侧tip数量
          rightTipCount: rightTipCount,
          // 总数，处理右侧比例时会用到
          totalNumber: totalData ? totalData.count || 0 : 0
        }
      } else if (currentClassification === 'expressNumber') {
        // 快递
        let chartTitle = ''
        let rightTipCount = '0'
        // 快递总数
        let totalData = this.findItem(list, 'totalExpressNumber')
        if (totalData) {
          chartTitle = this.stringWithNumFormat(totalData.count || 0)
        }
        // 今日新增快递
        let newAddData = this.findItem(list, 'newExpressToday')
        if (newAddData) {
          rightTipCount = this.stringWithNumFormat(newAddData.count || 0)
        }
        showData = {
          unit: '件',
          chartTitle: chartTitle,
          chartSubTitle: '快递总数',
          rightTipTitle: '今日新增快递',
          rightTipCount: rightTipCount,
          totalNumber: totalData ? totalData.count || 0 : 0
        }
      }

      let currentDetail = this.sourceDetailList.find(item => item.classification === currentClassification)
      if (currentDetail) {
        currentDetail = Object.assign({}, currentDetail, showData)
      }
      this.detailItem = currentDetail
    },

    findItem (list, key) {
      let item
      if (list && list.length > 0 && key && key.length > 0) {
        item = list.find(model => model.classification === key)
      }
      return item
    },
    // 请求数据
    async requestForData (modelIds) {
      let params = { modelIds }
      const { data, status } = await this.$axios.post(getDataKanBanSubDataURL, params)
      if (status === 100) {
        if (data) {
          this.dealWithData(data || [])
        }
      }
    },

    // 重置size
    eChartResize () {
      // 需要重新绘制，否则会不显示。resize不行；
      let _this = this
      // 需要加个延时，否则不显示
      setTimeout(() => {
        _this.startDraw()
      }, 200)
    },
    // 绘制图像
    startDraw () {
      this.chartsAry.forEach(function (v, i) {
        if (v.chart) {
          v.chart.clear()
        }
      })

      this.dealWithChartData()
    },

    // 转化
    stringWithNumFormat (num) {
      let tempNum = num || 0
      let suffixStr = ''
      if (tempNum >= 10000 && tempNum < 100000000) {
        tempNum = tempNum / 10000.00
        suffixStr = '万'
      } else if (tempNum > 100000000) {
        tempNum = tempNum / 100000000.00
        suffixStr = '亿'
      }
      tempNum = tempNum.toFixed(2)
      tempNum = parseFloat(tempNum)
      tempNum = tempNum.toLocaleString()
      // 返回的是字符串23,245.12保留2位小数
      return tempNum + suffixStr
    },

    // 处理访客通行的数据
    dealWithChartData () {
      if (this.appModule && this.appModule.classification) {
        let titleData = {
          text: this.detailItem.chartTitle || '0',
          subtext: this.detailItem.chartSubTitle || ''
        }
        let chartDataList = []
        this.showDataList.forEach(item => {
          chartDataList.push({
            value: item.count || 0,
            name: item.name,
            itemStyle: { color: item.color }
          })
        })
        let drawPieData = {
          eleId: this.chartPieId,
          titleData,
          data: chartDataList
        }
        this.drawChartPie(drawPieData)
      }
    },

    // 绘制环形饼图
    drawChartPie (drawPieData) {
      drawPieData = drawPieData || {}
      let eleId = drawPieData.eleId || ''
      let data = drawPieData.data || {}
      let titleData = drawPieData.titleData || {}

      let option = {
        title: {
          text: titleData.text || '',
          subtext: titleData.subtext || '',
          left: '35%',
          top: 'middle',
          textAlign: 'center',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 24,
            color: this.theme.worktop_primary_text_color || '#333333'
          },
          subtextStyle: {
            fontSize: 12,
            color: this.theme.worktop_minor_text_color || '#AAAAAA'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            legendHoverLink: false,
            hoverAnimation: false,
            radius: ['60%', '80%'],
            // 设置成相对的百分比
            center: ['40%', '55%'],
            label: {
              show: false
            },
            labelLine: {
              length: 5,
              length2: 8
            },
            data: data || []
          }
        ]
      }
      let chart = drawedChart(eleId, option)
      this.dealWithResizeChar(eleId, chart)
    },

    dealWithResizeChar (eleId, chart) {
      // 处理绘图数据，拖动浏览器的时候，会重新用到
      if (eleId && eleId.length > 0 && chart) {
        let findItem = this.chartsAry.find(itme => itme.name === eleId)
        if (!findItem) {
          this.chartsAry.push({ name: eleId, chart: chart })
        }
      }
    },

    resizeAllCharts () {
      this.chartsAry.forEach(function (v, i) {
        if (v.chart) {
          v.chart.resize()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
 .e-chart-pie-wrapper {
   padding: 0 24px 24px 24px;
   min-width: 452px;

   .chart-pie-data-content-wrapper {
     //除去标题外的内容
     display: flex;
     flex-direction: column;
     align-items: center;
    //  margin-top: 24px;

     .chart-pie-wrapper {
       // chart图
       width: 180px;
       height: 180px;
       flex-shrink: 0;
     }

     .data-content-wrapper {
       // 数据内容
       flex: 1;
       display: flex;
       flex-direction: column;
       justify-content: center;

       .data-cell-wrapper {
         width: 160px;
         display: flex;
         flex-direction: row;
         margin-top: 16px;
         justify-content: space-between;
         

         .cell-text {
           font-size:14px;
           color:#919FB0;
           line-height:20px;
           font-weight: 400;
         }

         .mark-wrapper {
           align-items: center;
           display: flex;
           flex-direction: row;

           .mark {
             width: 10px;
             height: 10px;
             border-radius: 50%;
             margin-right: 5px;
           }
         }

         .bold-text {
           font-weight: 500;
         }

         .right-text {
           text-align: right;
         }
         .center-text {
           text-align: center;
         }
       }
     }
   }
 }
</style>
