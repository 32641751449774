var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dataBoard" },
    [
      _c("module-box", { attrs: { appModule: _vm.appModule } }, [
        _c(
          "div",
          { staticClass: "data-board-wrapper" },
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "component-wrapper",
                style: { width: _vm.dataBoardWidth + "%" },
              },
              [
                _c(item.componentName, {
                  tag: "component",
                  attrs: { appModule: item.appModule || {} },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }