
const typeMap = {
  1: '工单处理',
  2: '今日工单',
  3: '订单处理',
  4: '访客通行',
  5: '临停车位',
  6: '快递数'
}

// 工单处理跳转要传的状态
const workOrderStatusMap = {
  // 待接收工单
  workOrderToBeReceived: 1,
  // 超时待接收工单
  overtimeReceivingWorkOrder: 1,
  // 待处理工单
  workOrderToBeProcessed: 6,
  // 超时待处理工单
  overtimeProcessingWorkOrder: 6,
  // 今日已处理工单
  workOrderProcessedToday: undefined,
  // 待回访工单
  workOrderToBeVisited: undefined
}

// 新工单处理跳转要传的状态
const workNewOrderStatusMap = {
  // 待接收工单
  workOrderToBeReceived: 1,
  // 超时待接收工单
  overtimeReceivingWorkOrder: 1,
  // 待处理工单
  workOrderToBeProcessed: 6,
  // 超时待处理工单
  overtimeProcessingWorkOrder: 6,
  // 今日已处理工单
  workOrderProcessedToday: undefined,
  // 待回访工单
  workOrderToBeVisited: undefined
}

// 订单处理跳转要传的状态
const orderStatusMap = {
  // 待接收订单
  orderToBeReceived: 1,
  // 超时待接收订单
  overtimeReceivingOrders: 1,
  // 待处理订单
  pendingOrders: 6,
  // 超时待处理订单
  overtimeOrderProcessing: 6,
  // 今日已处理订单
  ordersProcessedToday: undefined,
  // 售后中
  afterSale: undefined,
  // 待退款
  pendingRefund: undefined
}

export {
  typeMap,
  workOrderStatusMap,
  workNewOrderStatusMap,
  orderStatusMap
}
